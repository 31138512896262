@import "./variables.scss";

@mixin smDown {
    @media (max-width: #{$breakpoint-sm}) {
        @content;
    }
}

@mixin smUp {
    @media (min-width: #{$breakpoint-sm}) {
        @content;
    }
}

@mixin mdUp {
    @media (min-width: #{$breakpoint-md}) {
        @content;
    }
}

@mixin lgUp {
    @media (min-width: #{$breakpoint-lg}) {
        @content;
    }
}

@mixin xlUp {
    @media (min-width: #{$breakpoint-xl}) {
        @content;
    }
}

@mixin addCalendarIcon($presenceKey, $iconName) {
    $string: unquote($presenceKey);
    $icon: url("./assets/icons/#{$iconName}.svg");

    &--#{$string} {
        -webkit-mask-image: $icon;
        mask-image: $icon;
        @content;
    }
}

@mixin materialButtonTransition {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

// use in 'themify' mixin content to apply values based on used theme
@function themed($key) {
    @if $theme-map == null {
        @error "Could not find theme-map. Make sure themed() is wrapped in themify mixin: '@include themify($themes) { }'";
    }

    $value: map-get($theme-map, $key);

    @if $value == null {
        @error "No value has been found for key: <#{$key}>. Provide a valid theme variable key from _variables.scss, e.g. \"background\" for themes.<theme>.background #{map-get($theme-map, $key)}";
    }

    @return $value;
}
