@import "./variables.scss";
@import "./mixins.scss";

:root {
    @each $theme, $map in $themes {
        &.theme-#{$theme} {
            color-scheme: map-get($map, colorScheme);
        }
    }
}

// Browser view
#browser-view {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: visible;

    .main-menu {
        overflow-y: auto;
    }

    //Content
    #contentWrapper {
        flex-grow: 1;
        padding: 8px;
        overflow: auto;
        padding-left: 16px;
        padding-right: 16px;

        #content {
            margin: 0 auto;
            overflow: visible;

            @media screen and (max-width: $breakpoint-sm) {
                width: 100%;
            }

            @media screen and (min-width: $breakpoint-sm) {
                width: 94%;
            }
        }
    }

    //Content login
    .content-login .MuiLink-root {
        display: inline-block;
        vertical-align: middle;

        .MuiListItem-root {
            padding: 0 10px;

            .MuiListItemIcon-root {
                min-width: 0;

                @include themify($themes) {
                    color: themed("brandPrimary");
                }
            }
        }

        .MuiTypography-root {
            font-weight: 700;
            font-size: 1.25rem;
        }
    }
}

.brand-container {
    font-size: 1.75rem;
    padding: 1rem 0;
    text-align: center;

    .brand-logo {
        width: 48px;
        height: 48px;
        margin-top: -3px;
        margin-right: 12px;
        vertical-align: middle;
        display: inline-block;

        @include themify($themes) {
            color: themed("grayscaleLogo");
        }
    }

    .brand-title {
        display: inline;
        font-size: 1.75rem;
    }
}

#menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;

    a {
        padding-bottom: 16px;
        transition: background-color 0.4s;

        &:hover,
        &.active {
            @include themify($themes) {
                background-color: themed("highlightGrayscale");
            }
        }

        .fas {
            margin-right: 16px;
        }

        &.MuiLink-root {
            padding: 0;

            .MuiListItem-button {
                padding: 10px 16px;
                display: flex;
                align-items: center;

                .MuiListItemIcon-root {
                    min-width: 0;
                    box-sizing: content-box;

                    @include themify($themes) {
                        color: themed("brandPrimary");
                    }
                }

                .MuiListItemText-root span {
                    font-weight: 700;
                }
            }
        }

        svg {
            display: inline-flex;
            align-self: center;
            position: relative;
            padding-right: 8px;
        }
    }

    a {
        display: block;
        padding-top: 8px;
        padding-left: 16px;
    }

    .loggedas-info {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        flex-shrink: 0;

        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
        white-space: nowrap;
    }
}

a {
    text-decoration: none;
    font-weight: 700;

    @include themify($themes) {
        color: themed("brandPrimary");
    }
}

//Version
#version {
    font-size: 12px;
}

.custom-title {
    font-weight: 400;
    margin-bottom: 3rem;
}

.custom-subtitle {
    font-weight: 400;
}

.subtitle-lightweight {
    font-weight: 100;
    margin-bottom: 0;
}

.picker-container {
    border-radius: 4px;

    .item-list {
        height: 256px;
        user-select: none;
        display: block !important;
        overflow-y: scroll;

        @include themify($themes) {
            border-bottom: 1px solid themed("tableBorder");
            box-shadow: 0 3px 6px themed("containerShadow");
            -webkit-box-shadow: 0 3px 6px themed("containerShadow");
            -moz-box-shadow: 0 3px 6px themed("containerShadow");
        }

        label {
            padding: 0 25px;
            margin-right: 0 !important;
        }
    }

    .item--selected {
        @include themify($themes) {
            background-color: themed("highlightPrimary");
        }
    }
}

.userList {
    height: 256px;
    user-select: none;
    display: block !important;
    overflow-y: scroll;
}
