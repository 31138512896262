.autoSuggest--icon {
    margin-left: -2px;
    padding-right: 4px
}

.autoSuggest--valueContainer .autoSuggest--icon {
    display: inline-flex;
    align-self: center;
    top: .300em;
    position: relative;
}