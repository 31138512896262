// breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// colors
$paperDark: #424242;
$warning: #ac0906;
$warningDark: #850604;
$successLight: #81c784;
$successMain: #4caf50;
$successDark: #388e3c;
$waitingLight: #eede7e;
$waitingMain: #ebd65e;
$waitingDark: #d3c052;

$brand: (
    light: (
        primary: #183ec9,
        primaryHover: #102b8c,
        secondary: #183ec9,
        secondaryHover: #102b8c,
    ),
    dark: (
        primary: #a0c4ff,
        primaryHover: #7089b2,
        secondary: #a0c4ff,
        secondaryHover: #7089b2,
    ),
);

$themes: (
    light: (
        background: white,
        brandPrimary: map-get(map-get($brand, light), primary),
        brandPrimaryHover: map-get(map-get($brand, light), primaryHover),
        brandSecondary: map-get(map-get($brand, light), secondary),
        brandSecondaryHover: map-get(map-get($brand, light), secondaryHover),
        grayscaleLogo: black,
        buttonHover: rgba(black, 0.04),
        buttonShadow: rgba(black, 0.2),
        calendarIcon: rgba(black, 0.54),
        calendarIconImportant: #d5202a,
        colorScheme: light,
        containerShadow: rgba(black, 0.16),
        dayHeader: rgba(black, 0.54),
        dayHighlight: rgba(black, 0.06),
        dayNumber: rgba(black, 0.76),
        highlightPrimary: rgba(map-get(map-get($brand, light), primary), 0.12),
        highlightGrayscale: rgba(black, 0.06),
        paper: rgba(white, 1),
        popoverHeader: #eeeeee,
        tableBorder: rgba(black, 0.12),
        tooltipBackground: rgba(97, 97, 97, 0.9),
        calendarEventBackground: #7e7e7e,
        calendarEventBackgroundHover: map-get(map-get($brand, light), primary),
        calendarEventColor: white,
        specialEventBackground: rgba(black, 0.01),
        specialEventBorder: rgba(black, 0.1),
        acceptedOvertimeBackground: $successMain,
        acceptedOvertimeBackgroundHover: $successDark,
        acceptedOvertimeColor: white,
        pendingOvertimeBackground: $waitingMain,
        pendingOvertimeBackgroundHover: $waitingDark,
        pendingOvertimeColor: $paperDark,
        rejectedOvertimeBackground: $warning,
        rejectedOvertimeBackgroundHover: $warningDark,
        rejectedOvertimeColor: white,
    ),
    dark: (
        background: #303030,
        brandPrimary: map-get(map-get($brand, dark), primary),
        brandPrimaryHover: map-get(map-get($brand, dark), primaryHover),
        brandSecondary: map-get(map-get($brand, dark), secondary),
        brandSecondaryHover: map-get(map-get($brand, dark), secondaryHover),
        grayscaleLogo: white,
        buttonHover: rgba(white, 0.2),
        buttonShadow: rgba(black, 0.2),
        calendarIcon: rgba(white, 0.54),
        calendarIconImportant: #ef5350,
        colorScheme: dark,
        containerShadow: rgba(black, 0.64),
        dayHeader: rgba(white, 0.54),
        dayHighlight: rgba(white, 0.06),
        dayNumber: rgba(white, 0.76),
        highlightPrimary: rgba(map-get(map-get($brand, dark), primary), 0.12),
        highlightGrayscale: rgba(white, 0.1),
        paper: rgba($paperDark, 1),
        popoverHeader: #222222,
        tableBorder: rgba(white, 0.12),
        tooltipBackground: rgba(97, 97, 97, 0.9),
        calendarEventBackground: #525252,
        calendarEventBackgroundHover: map-get(map-get($brand, dark), primary),
        calendarEventColor: #f3f5f6,
        specialEventBackground: rgba(white, 0.1),
        specialEventBorder: rgba(white, 0.15),
        acceptedOvertimeBackground: $successLight,
        acceptedOvertimeBackgroundHover: $successMain,
        acceptedOvertimeColor: #222222,
        pendingOvertimeBackground: $waitingLight,
        pendingOvertimeBackgroundHover: $waitingMain,
        pendingOvertimeColor: $paperDark,
        rejectedOvertimeBackground: $warning,
        rejectedOvertimeBackgroundHover: $warningDark,
        rejectedOvertimeColor: white,
    ),
);
