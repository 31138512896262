@import "../../../mixins.scss";

%table-wrapper {
    width: 100%;
    max-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    overflow: auto;

    .table-container {
        box-sizing: content-box;

        .table {
            min-width: 500px;
        }
    }
}

.pagination-table-wrapper {
    @extend %table-wrapper;

    @include smUp {
        max-height: calc(100vh - 86px);
    }

    .pagination-buttons {
        display: inline-flex;
        flex-shrink: 0;
        margin-left: 3px;
    }
}

.result-table-wrapper {
    @extend %table-wrapper;

    @include smUp {
        max-height: calc(100vh - 64px);
    }
}
