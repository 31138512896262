body,
html,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
